<template>
    <main class="pricing-wrapper">
        <section class="page-start">
            <h1 class="page-name">{{ $t('pricing.intro.h1') }}.
                <span class="gray">{{ $t('pricing.intro.h2') }}</span>
            </h1>
        </section>

        <div class="pricing-cover"
         :style="{ 'background-image': 'url(' + pricingCover + ')' }">
        </div>

        <div class="pricing">
            <section class="section">
                <HorizontalSelect :options="options"
                 @input="(id) => this.tab = id" />

                <div class="space-between">
                    <h2 class="pricing-topic">{{ this.tabName[this.tab] }}</h2>
                    <NewButton v-if="(this.tab != 0 && this.bookingButton)"
                     :text="this.$t('pricing.other.booking')"
                     @click="toBooking" />
                </div>

                <transition name="bounce"
                 mode="out-in">
                    <PricingGrid :json="plans"
                     v-if="(this.tab == 0)" />
                    <PricingGrid :json="tests"
                     v-else-if="(this.tab == 1)"
                     mode="tests" />
                    <PricingGrid :json="sgt"
                     mode="personal"
                     v-else />
                </transition>
            </section>

            <section class="section">
                <div class="space-between">
                    <h2 class="pricing-topic">{{ $t('pricing.other.additional') }}</h2>
                    <NewButton :text="this.$t('pricing.other.order')"
                       @click="toBooking" />
                </div>
                <PricingGrid :json="services"
                 mode="services" />
            </section>
        </div>
    </main>
</template>

<script>
import PricingGrid from '@/components/PricingGrid.vue';
import pricingCover from '@/assets/img/pricing/pricing-cover.webp';
import plans from '@/resources/plans.json'
import tests from '@/resources/tests.json'
import sgt from '@/resources/sgt.json'
import services from '@/resources/services.json'
import NewButton from '@/components/buttons/NewButton.vue';
import router from '@/router';
import { useMeta } from 'vue-meta'
import HorizontalSelect from '@/components/selectors/HorizontalSelect.vue';
import { state } from '@/state.js'

export default {
    name: 'PricingView',
    components: {
        PricingGrid,
        NewButton,
        HorizontalSelect
    },
    data() {
        return {
            plans,
            tests,
            services,
            state,
            sgt,
            vh: window.visualViewport.height + 'px',
            pricingCover,
            tab: 0,
            bookingButton: (localStorage.getItem('token') != null),
            tabName: [
                this.$t('pricing.other.segments.plan'),
                this.$t('pricing.other.segments.tests'),
                this.$t('pricing.other.segments.personal')
            ],
            options: [{
                name: this.$t('pricing.other.groups.circleTraining'),
                id: 0
            },
            {
                name: this.$t('pricing.other.groups.tests'),
                id: 1
            },
            {
                name: this.$t('pricing.other.groups.groupTraining'),
                id: 2
            }]
        };
    },
    methods: {
        toBooking() {
            if (localStorage.getItem('token')) {
                this.state.toggleBooking();
            } else {
                router.push('/login');
            }
        }
    },
    beforeMount() {
        useMeta({
            title: this.$t('pagenames.pricing') + " | Connected to result",
            link: [
                { rel: 'canonical', href: 'https://35fit.com/pricing' },
            ],
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: [
                { property: "og:title", content: "35FIT | " + this.$t('pagenames.pricing') + " | AI gym" },
                { property: "og:description", content: this.$t('meta.pricing') },
                { property: "og:url", content: "https://35fit.com/pricing" },
            ],
            description: this.$t('meta.pricing')
        })
        switch (this.$i18n.locale) {
            case 'en':
                this.plans = this.plans.plans.en
                this.sgt = this.sgt.plans.en
                this.tests = this.tests.plans.en
                this.services = this.services.plans.en
                break;
            case 'et':
                this.plans = this.plans.plans.et
                this.sgt = this.sgt.plans.et
                this.tests = this.tests.plans.et
                this.services = this.services.plans.et
                break;
            case 'ru':
                this.plans = this.plans.plans.ru
                this.sgt = this.sgt.plans.ru
                this.tests = this.tests.plans.ru
                this.services = this.services.plans.ru
                break;
            default:
                this.services = this.services.plans.en
                this.sgt = this.sgt.plans.en
                this.tests = this.tests.plans.en
                this.plans = this.plans.plans.en
                break;
        }
    },



}
</script>

<style>
.pricing {
    display: block;
    background-color: white;
    width: 100%;
}

.pricing-topic {
    margin: 30px 0;
}

.pricing-cover {
    margin-top: 46px;
    height: v-bind(vh);
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.section {
    margin: 40px auto;
}
</style>
