<template>
    <transition name="side"
     mode="out-in">
        <LoaderView v-if="loader" />
        <div v-else
         class="class-book">

            <NotifyWarning v-if="error.show"
             :title="$t('dashboard.pages.booking.modal.errorTitle')"
             :text="error.text"
             color="red"
             :icon="xmark"
             @close="error.show = false" />

            <div v-if="activeTariff === null" ></div>

            <div v-else-if="activeTariff === false">
                <p
                    v-html="$t('dashboard.pages.booking.modal.availableForActive')">
                </p>
                <p style="margin-top: 10px">
                    <router-link to="/pricing">
                        <MainButton v-on:click="state.toggleBooking()" :text="$t('dashboard.pages.booking.modal.chooseTariff')" />
                    </router-link>
                </p>
            </div>

            <div v-else-if="activeTariff === true && debt === true">
                <p
                    v-html="$t('dashboard.pages.booking.modal.availableForWithoutDebt')">
                </p>
            </div>

            <div v-else>

                <div v-if="isTrial"
                 class="trial-pic">
                    <img class="new-contract-img"
                     :src="trial"
                     alt="Boy and a girl - 35FIT">
                </div>

                <InputNotify v-if="isTrial"
                 :text="$t('dashboard.pages.booking.modal.firstTrialText')"
                 style="max-width: 100%;" />

                <OptionsSelect :heading="$t('dashboard.pages.booking.modal.selectService')"
                 :options="isTrial ? [optionsSelect[0]] : optionsSelect"
                 @selected="(id) => selected(id)" />

                <transition name="left"
                 mode="out-in">

                    <div v-if="this.bookingType === 'test'"
                     class="select-group">
                        <h3>Test</h3>
                        <SelectTag :options="testsOption"
                         :label="testsOption[0]"
                         @select="(value) => this.testType = value" />
                    </div>

                    <div v-else-if="this.bookingType === 'additional'"
                         class="select-group">
                        <h3>{{ $t('dashboard.pages.booking.modal.selectAdditionalService') }}</h3>
                        <SelectTag :options="additionalServices"
                                   :label="additionalServices[0].name"
                                   mode="json"
                                   @select="(value) => this.selectedAdditionalService = value" />
                    </div>

                    <div v-else-if="this.bookingType === 'SGT'"
                     class="select-group">
                        <h3>SGT</h3>
                        <SelectTag :options="testsOption"
                         :label="'ZEN'" />
                    </div>

                </transition>

                <transition name="left">

                    <div v-if="bookingType && bookingType !== 'additional'"
                     class="select-group">
                        <h3>{{ $t('dashboard.pages.booking.modal.dateTime') }}</h3>

                        <SelectTag :key="updateDate"
                         :options="dateOptions"
                         :label="this.selectedDate"
                         @select="(selected) => formTimeOptions(selected)" />

                        <SelectTag v-if="this.selectedDate != $t('dashboard.pages.booking.modal.selectDate')"
                         :key="updateComp"
                         :options="timeOptions"
                         :label="this.selectedTime"
                         @select="(time) => this.selectedTime = time" />
                    </div>

                    <div v-else-if="this.bookingType === 'additional' && this.selectedAdditionalService === 'freeze'"
                         class="select-group">
                        <h3>{{ $t('dashboard.pages.booking.modal.selectFreezePeriod') }}</h3>
                        <div>
                        <input class="input-full-width" type="date"
                               :min='minFreezeDate'
                               v-model="freezeDateStart"
                        >
                        </div>
                        <SelectTag :options="freezePeriods"
                                   :label="freezePeriods[0].name"
                                   mode="json"
                                   @select="(value) => this.selectedFreezePeriod = value" />
                    </div>

                </transition>

                <MainButton
                 v-if="(this.selectedTime != $t('dashboard.pages.booking.modal.selectTime')
                    && this.selectedTime != 'No time for today'
                    && this.selectedTime != this.$t('dashboard.pages.booking.modal.noWeekends')
                    && this.selectedTime != this.$t('dashboard.pages.booking.modal.noAvailable'))
                    || this.bookingType === 'additional'"
                 :widthRestrict="true"
                 @click="createReserve"
                 :text="this.bookingType === 'additional' ? $t('dashboard.pages.booking.modal.orderService') : $t('dashboard.pages.booking.modal.createBooking')" />
            </div>
        </div>
    </transition>
</template>

<script>
import OptionsSelect from '@/components/selectors/OptionsSelect.vue';
import SelectTag from './selectors/SelectTag.vue';
import heart from '@/assets/svg/heart.svg';
import personal from '@/assets/svg/personal.svg';
import health from '@/assets/svg/health.svg';
import elliptical from '@/assets/svg/elliptical.svg';
import MainButton from './buttons/MainButton.vue';
import LoaderView from './buttons/LoaderView.vue';
import NotifyWarning from './warnings/NotifyWarning.vue';
import xmark from '@/assets/svg/xmark.svg';
import InputNotify from './InputNotify.vue';
import trial from '@/assets/img/dashboard/trial.svg';
import { state } from '@/state.js'

export default {
    name: "BookClass",
    props: {
        isTrial: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        OptionsSelect,
        SelectTag,
        MainButton,
        NotifyWarning,
        LoaderView,
        InputNotify
    },
    data() {
        return {
            state,
            error: {
                show: false,
                text: '',
            },
            trial,
            activeTrials: null,
            activeTariff: null,
            debt: true,
            schedule: [],
            freezeDateStart: Date.now(),
            minFreezeDate: Date.now(),
            xmark,
            loader: false,
            updateComp: 0,
            updateDate: 5,
            selectedDate: this.$t('dashboard.pages.booking.modal.selectDate'),
            selectedTime: this.$t('dashboard.pages.booking.modal.selectTime'),
            selectedAdditionalService: "water",
            selectedFreezePeriod: "1month",
            testType: "Milon test",
            bookingType: '',
            optionsSelect: [
                {
                    id: 'milon',
                    name: 'Circle (Milon) ' + this.$t('dashboard.pages.booking.modal.training'),
                    shortText: this.$t('dashboard.pages.booking.modal.milonText'),
                    icon: elliptical,
                    price: this.$t('dashboard.pages.booking.modal.included'),
                    time: '35 ' + this.$t('dashboard.pages.booking.modal.minutes'),
                    other: [
                        {
                            text: this.$t('dashboard.pages.booking.modal.milonOther.text1'),
                        },
                        {
                            text: this.$t('dashboard.pages.booking.modal.milonOther.text2'),
                        },
                    ]
                },
                {
                    id: 'test',
                    name: this.$t('dashboard.pages.booking.modal.tests.name'),
                    shortText: this.$t('dashboard.pages.booking.modal.tests.text'),
                    icon: heart,
                    price: this.$t('dashboard.pages.booking.modal.tests.price'),
                    time: '5 - 35 ' + this.$t('dashboard.pages.booking.modal.minutes'),
                },
                {
                    id: 'personal',
                    name: this.$t('dashboard.pages.booking.modal.personal.name'),
                    shortText: this.$t('dashboard.pages.booking.modal.personal.text'),
                    icon: personal,
                    price: this.$t('dashboard.pages.booking.modal.personal.price'),
                    time: '40 ' + this.$t('dashboard.pages.booking.modal.minutes'),
                },
                {
                    id: 'additional',
                    name: this.$t('dashboard.pages.booking.modal.additional.name'),
                    shortText: this.$t('dashboard.pages.booking.modal.additional.text'),
                    icon: health,
                }

            ],
            testsOption: ["Milon test"],
            additionalServices: [{name: this.$t('dashboard.pages.booking.modal.additionalServices.water'), id: "water"}, {name: this.$t('dashboard.pages.booking.modal.additionalServices.freeze'), id: "freeze"}],
            freezePeriods: [{name: this.$t('dashboard.pages.booking.modal.additionalServices.oneMonth'), id: "1month"}, {name: this.$t('dashboard.pages.booking.modal.additionalServices.twoMonth'), id: "2month"}],
            dateOptions: [
            ],
            timeOptions: [
            ],
        }
    },

    // on bookingtype value change
    methods: {
        showError(text) {
            this.error = {
                show: true,
                text: text,
            }
        },
        selected(id) {
            this.bookingType = id;
            this.formDateOptions()
            this.selectedDate = this.$t('dashboard.pages.booking.modal.selectDate');
            this.updateDate++;
            this.selectedTime = this.$t('dashboard.pages.booking.modal.selectTime');
            this.updateComp++;
        },
        formDateOptions() {
            // NOTE: trial training is available only for 7 days from 17.04
            let date = new Date();
            // if (this.isTrial) {
            //     date = new Date('2023-04-16');
            // } else {
            // }

            let today = `${(date.getDate() < 10 ? '0' : '') + (date.getDate())}.${((date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1))}.${date.getFullYear()}`;
            let options = [];
            for (let i = 0; i < 7; i++) {
                let day = (date.getDate() < 10 ? '0' : '') + (date.getDate()); // add 0 to the day if it's less than 10
                let month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) // add 0 to the month if it's less than 10
                let year = date.getFullYear();
                let addDate = `${day}.${month}.${year}`


                if (this.bookingType != 'milon') {
                    if (addDate != today) {
                        options.push(addDate);
                    }
                } else {
                    if (this.isTrial) {

                        if (addDate != today) {
                            options.push(addDate);
                        }
                    } else {
                        options.push(addDate);
                    }
                }

                date.setDate(date.getDate() + 1);
            }
            this.dateOptions = options;
        },
        isWeekend(date) {
            let dateObj = new Date(date.split('.').reverse().join('-'));

            let day = dateObj.getDay();
            let isWeekend = day === 0 || day === 6;

            return isWeekend;
        },
        isNewYear(date) {
            let dateObj = new Date(date.split('.').reverse().join('-'));

            let day = dateObj.getDay();
            let isNewYear = day === 1;

            return isNewYear;
        },
        milonTrialOptions(date) {
            var options = [];
            let thisDayTrials = this.activeTrials.filter(trial => {
                return Date.parse(trial.start.split('T')[0]) == Date.parse(date.split('.').reverse().join('-'))
            })
            let languages = {
                23996: ["Русский", "Eesti"],
                25332: ["Русский", "English", "Eesti"]
            }
            function r (e, t) {
                let n = e.filter(e => t.indexOf(e) >= 0);
                return n.length > 0
            }

            let day = date.split('.').reverse().join('-');
            let timeForUser = [];
            let userLanguages = [];
            "en" === localStorage.getItem("lang") ? userLanguages.push("English") : "ru" === localStorage.getItem("lang") ? userLanguages.push("Русский") : userLanguages.push("Eesti")

            this.schedule.forEach(function(e) {
                let t = e.start.split("T");
                let n = e.end.split("T");
                t[0] === day && languages[e.id] && r(languages[e.id], userLanguages) && timeForUser.push({
                    start: t[1].split(":")[0],
                    end: n[1].split(":")[0]
                })}
            );

            timeForUser.sort((e,t) => e.start - t.start);

            timeForUser.forEach(function(e) {
                for (let i = e.start; i < e.end; i++) {
                    let isAvailable = true;
                    thisDayTrials.forEach(trial => {
                        let trialTime = trial.start.split("T")[1].split(":")[0];
                        if (trialTime == i) {
                            isAvailable = false;
                        }
                    });
                    isAvailable && -1 === options.indexOf(`${i}:00`) && options.push(`${i}:00`)
                }
            });

            this.timeOptions = options;

            if (this.timeOptions.length == 0) {
                this.warningText = this.$t('dashboard.pages.booking.modal.notime');
            }
        },
        noWeekendsOptions(date) {
            var options = []

            if (this.isWeekend(date)) {
                options.push(this.$t('dashboard.pages.booking.modal.noWeekends'));
            } else {
                if (this.isTrial) {

                    // find all trials for this day
                    let thisDayTrials = this.activeTrials.filter(trial => {
                        return Date.parse(trial.start.split('T')[0]) == Date.parse(date.split('.').reverse().join('-'))
                    })
                    let start_time = 10
                    let end_time = 14
                    let day = new Date(date.split('.').reverse().join('-')).getDay();
                    if (day === 2 || day === 4) {
                        start_time = 16
                        end_time = 20
                    }

                    // default start time for trial
                    // let start_time = 11
                    // let end_time = 17
                    // if eesti is selected
                    // if (localStorage.getItem('eesti') == 'true') {
                    //     start_time = 11
                    //     end_time = 14
                    // }

                    for (let i = start_time; i < end_time; i++) {
                        // check if time is available
                        let isAvailable = true;
                        thisDayTrials.forEach(trial => {
                            let trialTime = trial.start.split('T')[1].split(':')[0];
                            if (trialTime == i) {
                                isAvailable = false;
                            }
                        })
                        if (isAvailable) {
                            options.push(`${i}:00`);
                        }
                    }

                } else if (this.bookingType == 'sgt') {
                    let dateObj = new Date(date.split('.').reverse().join('-'));
                    let day = dateObj.getDay();

                    switch (day) {
                        case 4:
                        case 1:
                            options.push(`17:00`);
                            break;
                        case 5:
                        case 2:
                            options.push(`12:00`);
                            break;
                        case 3:
                            options.push(`11:00`);
                            break;
                        default:
                            options.push(`16:00`);
                            break;
                    }
                } else {
                    for (let i = 10; i < 18; i++) {
                        options.push(`${i}:00`);
                    }
                }
            }

            this.timeOptions = options;
        },
        formTimeOptions(date) {
            // reset time options
            this.selectedTime = this.$t('dashboard.pages.booking.modal.selectTime');
            this.updateComp++;
            let dateObj = new Date()
            let today = `${(dateObj.getDate() < 10 ? '0' : '') + (dateObj.getDate())}.${((dateObj.getMonth() + 1 < 10 ? '0' : '') + (dateObj.getMonth() + 1))}.${dateObj.getFullYear()}`
            this.selectedDate = date;
            // local options variable
            let hour = dateObj.getHours() + 1;
            var options = []
            switch (this.bookingType) {
                case 'milon':
                    if (this.isTrial) {
                        this.milonTrialOptions(date)
                    } else {
                        // milon options for weekends are different
                        if (this.isWeekend(date)) {
                            if (today != date) {
                                hour = 9
                            }
                            for (let i = hour; i < 19; i++) {
                                options.push(`${i}:00`);
                                options.push(`${i}:30`);
                            }
                            this.timeOptions = options;
                        } else {
                            if (today != date) {
                                hour = 7
                            }
                            if (hour < 7) {
                                hour = 7
                            }
                            for (let i = hour; i < 21; i++) {
                                options.push(`${i}:00`);
                                if (i != 20) {
                                    options.push(`${i}:30`);
                                }
                            }
                            if (options.length > 0) {
                                this.timeOptions = options;
                            } else {
                                options = ["No time for today"];
                            }
                            this.timeOptions = options;
                        }
                    }

                    break;
                case 'sgt':
                case 'personal':
                case 'test':
                    this.milonTrialOptions(date)
                    //this.noWeekendsOptions(date)
                    break;
                default:
                    console.log("default");
                    break;
            }

        },
        async getTrials() {
            fetch('https://app.35fit.com/api/users/reserves/upcoming/trials', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    this.activeTrials = data;
                })
                .catch(err => {
                    console.log(err)
                    if (err.message == 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                })
        },
        async getSchedule() {
            fetch("https://app.35fit.com/api/users/reserves/upcoming/schedule", {
                method: "GET"
            })
                .then(res => {
                    if (200 !== res.status)
                        throw new Error(res.status);
                    return res.json()
                })
                .then(data => {
                    this.schedule = data;
                })
                .catch(err => {
                    console.log(err)
                })
        },
        createReserve() {
            this.loader = true;
            fetch('https://app.35fit.com/api/users/reserves', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    type: this.bookingType,
                    date: this.selectedDate,
                    time: this.selectedTime,
                    service: this.selectedAdditionalService,
                    freezeDateStart: this.freezeDateStart,
                    period: this.selectedFreezePeriod,

                })
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        state.toggleBooking();
                        return;
                    }
                    this.loader = false;
                    this.showError(result.error)
                })
                .catch(error => {
                    this.loader = false;
                    this.showError(error.message)
                    if (error.message == 401) {
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                });
        },
        async is_trial() {
            await fetch('https://app.35fit.com/api/users/is_trial', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },

            })
                .then(res => res.json())
                .then(data => {
                    if (data.is_trial) {
                        this.hasDebt = (data.debt > 0) ? true : false
                        this.props.isTrial = true
                        localStorage.setItem('eesti', data.eesti)
                        if (this.hasDebt) {
                            this.openMenuPage('invoices')
                        } else {
                            this.toSchedule()
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getActiveTariff() {
            await fetch('https://app.35fit.com/api/users/ticket', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    if (response.ticket) {
                        this.activeTariff = true
                        if (parseFloat(response.ticket.debt) < 0.01) {
                            this.debt = false
                        }
                    } else {
                        this.activeTariff = false
                    }
                }
            })
            .catch()
        }
    },
    mounted() {
        this.is_trial();
        this.getTrials();
        this.getSchedule();
        this.getActiveTariff();
        const minFreezeDate = new Date();
        minFreezeDate.setDate(minFreezeDate.getDate() + 1);
        const minFreezeDateFormatted = minFreezeDate.toISOString().split('T')[0];
        this.freezeDateStart = minFreezeDateFormatted;
        this.minFreezeDate = minFreezeDateFormatted;
    },

}

</script>

<style>
.class-book {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.select-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.trial-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
}

.input-full-width {
    max-width: 1000px;
}

</style>
