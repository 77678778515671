<template>
    <section class="intro">

        <div class="mob-full-width">
            <h1 ref="introText"
             class="intro-text"
             v-html="$t('advert.title')">
            </h1>
            <ul class="intro-features">
                <li v-for="description in [$t('advert.descriptionFirst'), $t('advert.descriptionSecond'), $t('advert.descriptionThird'), $t('advert.descriptionFourth')]"
                    :key="description"
                    ref="feature">
                    <div class="list-icon"></div>
                    {{ description }}
                </li>
            </ul>

            <MainButton :text="$t('pricing.other.booking')"
                id="regButton"
                @click="openBooking()"
                style="margin: 0; text-transform: capitalize;"
                :widthRestrict="true" />
        </div>


        <div @click="nextSection"
         role="button"
         v-bind:style='{ backgroundImage: "url(" + arrow + ")", }'
         class="arrow">
        </div>

        <img class="intro-pp-people"
         src="/pics/group_training.webp"
         alt="People at 35FIT">

        <div ref="introCircle"
         class="intro-red-bg-circle"></div>

    </section>
</template>

<script>
import gsap from 'gsap'
import arrow from '@/assets/svg/arrow.svg'
import people from '@/assets/img/people.png'
import MainButton from '@/components/buttons/MainButton.vue'
import { state } from '@/state.js'
import router from '@/router';
export default {
    name: "FreeTrial",
    data() {
        return {
            arrow: arrow,
            state,
            show: localStorage.getItem('token') ? false : true,
            people,
            features: [
                this.$t('advert.regCampaignText1'),
                this.$t('advert.regCampaignText2'),
                this.$t('advert.regCampaignText3'),
            ]
        };
    },
    components: {
        MainButton,
    },
    methods: {
        openBooking() {
            if (localStorage.getItem('token')) {
                this.state.toggleBooking();
            } else {
                router.push('/login');
            }
        },
        enterAnimate() {
            const tl = gsap.timeline();
            const circle = this.$refs.introCircle;
            let features = this.$refs.feature;
            features.push(document.getElementById('regButton'));

            tl
                .fromTo(circle, {
                    scale: 0.9,
                }, {
                    scale: 1.2,
                    duration: 5,
                    ease: "ease",
                    smoothOrigin: true,
                    repeat: -1,
                    yoyo: true,
                }, 'start')
                .fromTo(features,
                    {
                        opacity: 0,
                        y: 20,
                    }, {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    ease: "expo",
                    stagger: 0.2,
                }, 'start+=1')

        },
        nextSection() {
            window.scrollTo({
                top: (window.visualViewport.height),
                behavior: "smooth",
            });
        },
        toRegister() {
            this.$router.push("register");
            this.$emit("toRegister");
        },

    },
    mounted() {
        // this.enterAnimate();
    },


}
</script>

<style>
.intro {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: var(--intro-content-padding);
    height: 100vh;
    height: 100svh;
    min-height: 600px;
    background: linear-gradient(180deg, rgba(141, 141, 141, 0.5) 0%, rgba(255, 255, 255, 0) 13.87%);
    background-size: cover;
    overflow: hidden;
}

.pp-schedule {
    width: 100%;
    margin-top: 20px;
    display: flex;
    gap: 5px;
    overflow-x: scroll;
    padding-bottom: 20px;
}

.reg-free {
    margin-top: 20px;
    max-width: 450px;
    font-size: 18px;
    font-weight: 500;
}

.intro-register {
    margin: 10px 0 !important;
}

.intro-features {
    list-style: none;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 25px;
    font-weight: 600;
}

.intro-features li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.intro-pp-people {
    position: absolute;
    bottom: 0;
    right: -150px;
    height: 90%;
    z-index: 2;
    object-fit: contain;
    transition: all 0.5s ease;
}


.intro-red-bg-circle {
    position: absolute;
    bottom: -420px;
    right: -150px;
    height: 800px;
    width: 800px;
    z-index: 1;
    background-color: var(--light-green);
    border-radius: 50%;
    transition: all 0.5s ease;
}

.mob-full-width {
    z-index: 5;
}

.arrow {
    width: 38px;
    height: 38px;
    z-index: 5;
    background-color: rgba(56, 49, 49, 0.3);
    backdrop-filter: blur(20px);
    background-position: center center;
    background-repeat: repeat-y;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    animation-name: scrollcue;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.54, -0.03, 0, 0.96);
    animation-delay: 1s;
}

@keyframes scrollcue {
    0% {
        background-position-y: 0px;
    }

    20% {
        background-position-y: 38px;
    }

    100% {
        background-position-y: 38px;
    }
}

.arrow:hover {
    background-color: rgba(25, 28, 35, .8);
}

.intro-text {
    text-transform: uppercase;
    color: black;
    font-weight: 900;
    font-size: 60px;
    line-height: 1.1;
    margin-top: 80px;
}

.schedule-highlight {
    background-color: var(--dark-blue);
    color: white;
}


@media screen and (max-width: 1680px) {
    .intro-pp-people {
        height: 70%;
        right: -60px;
        bottom: -60px;
    }

    .intro-red-bg-circle {
        top: unset;
        height: 600px;
        width: 600px;
        bottom: -60px;
    }
}

@media screen and (max-width: 940px) {
    .intro-pp-people {
        height: 60%;
    }

    .intro-red-bg-circle {
        height: 600px;
        width: 600px;
        bottom: -200px;
    }

    .intro-text {
        font-size: 40px;
    }
}

@media screen and (max-width: 860px) {
    .intro-pp-people {
        height: 55%;
    }


    .intro-red-bg-circle {
        height: 800px;
        width: 800px;
        right: -200px;
        bottom: -560px;
    }
}

@media screen and (max-width: 500px) {
    .intro-pp-people {
        height: 50%;
        bottom: -80px;
    }

    .intro-red-bg-circle {
        width: 600px;
        height: 600px;
        bottom: -460px;
    }

    .intro-text {
        color: black;
        font-size: 35px;
        margin-top: 60px;
    }

    .mob-full-width {
        width: 100%;
    }

    .intro-features {
        font-size: 18px;
    }


}

@media screen and (max-width: 360px) {

    .intro-text {
        color: black;
        font-size: 29px;
        margin-top: 60px;
    }


}
</style>
